import {
  Box,
  HStack,
  Heading,
  Image,
  Link,
  Stack,
  Text,
} from '@chakra-ui/react';
import './App.css';

function App() {
  return (
    <Stack
      w={'full'}
      minH={'100vh'}
      bg={
        'radial-gradient(rgb(144, 199, 71), rgb(128, 184, 68), rgb(113, 168, 66), rgb(99, 153, 62), rgb(85, 138, 58), rgb(73, 123, 54), rgb(62, 108, 50), rgb(51, 94, 45), rgb(42, 80, 40), rgb(33, 66, 34))'
      }
      alignItems={'center'}
      justifyContent={'center'}
    >
      <HStack
        flexWrap={'wrap'}
        gap={'2rem'}
        alignItems={'center'}
        justifyContent={'center'}
      >
        <Stack
          h={{ md: '100vh' }}
          alignItems={'center'}
          justifyContent={'space-between'}
          p={'2rem'}
        >
          <Image
            w={{ base: '350px', md: '400px' }}
            src={'/images/cerebrium-w.png'}
            alt={'Cerebium - Tulcea Neurofeedback'}
          />
          <Image
            w={{ base: '350px', md: '500px' }}
            src={'/images/brainmap-w.png'}
            alt={'Cerebium - Tulcea Neurofeedback'}
          />
        </Stack>
        <Stack p={'2rem'} color={'textLight'}>
          <Heading
            color={'mainBlue'}
            mb={'-1rem'}
            fontSize={'1.5rem'}
            as={'h3'}
          >
            Neurofeedback IFL
          </Heading>
          <Heading fontSize={{ base: '2rem', md: '3rem' }} as={'h1'}>
            Cerebrium Tulcea
          </Heading>
          <Text
            fontWeight={'bold'}
            fontSize={'2rem'}
            w={{ base: '330px', md: '400px' }}
          >
            Ne găsiți la adresa:
            <br />
            Str. Isaccei, Nr. 31 Bl. 18, Sc. A, Ap. 6 - Parter
          </Text>
          <Link
            target="_blank"
            aria-label="Facebook"
            rel="noreferrer"
            href={'https://cerebrium.ro/'}
          >
            <Box
              w={'fit-content'}
              bg={'mainBlue'}
              px={'1.5rem'}
              py={'.75rem'}
              borderRadius={'1rem'}
              fontWeight={'bold'}
            >
              <Text w={'fit-content'}>Programează-te la noi!</Text>
            </Box>
          </Link>
        </Stack>
        <Stack
          p={'2rem'}
          h={{ md: '100vh' }}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Image
            w={{ base: '350px', md: '400px' }}
            src={'/images/neurofeed-w.webp'}
            alt={'Cerebium - Tulcea Neurofeedback'}
          />
          <Image
            mb={'4rem'}
            w={{ base: '350px', md: '500px' }}
            src={'/images/psihoterapie-w.png'}
            alt={'Cerebium - Tulcea Neurofeedback'}
          />
        </Stack>
      </HStack>
    </Stack>
  );
}

export default App;
